<template>
  <v-card>
    <v-card-title class="pb-8">
      <v-icon left>mdi-account</v-icon>
      Redefinir senha
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              ref="passwordFieldOne"
              v-model="form.newPassword"
              dense
              label="Nova senha *"
              :counter="20"
              maxlength="20"
              :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="[
                rules.passwordRules,
                rules.password8Rules,
                rules.passwordNumberRules,
                rules.passwordUpperCaseRules,
                rules.passwordLowercaseCaseRules,
                rules.passwordSpecialCharacterRules,
                rules.passwordAlreadyUsed,
              ]"
              outlined
              persistent-placeholder
              hide-details="true"
              @click:append="showConfirmPassword = !showConfirmPassword"
              @change="updatePassword"
              @input="matchPassword(true)"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <!-- <v-text-field v-model="form.emailAddress" label="Confirme sua nova senha" outlined persistent-placeholder :disabled='!isEditMode' /> -->
            <v-text-field
              v-model="form.newPasswordConfirmation"
              dense
              label="Confirme sua nova senha"
              :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmNewPassword ? 'text' : 'password'"
              :error-messages="passwordConfirmMessage"
              maxlength="20"
              outlined
              @click:append="showConfirmNewPassword = !showConfirmNewPassword"
              @input="matchPassword()"
            />
          </v-col>
        </v-row>
        <v-row v-show="showErrorPassword == true" style="position: relative; padding: 0px 0px 0px 0px; top: -29px">
          <v-col cols="6" class="my-0 py-0">
            <div
              v-for="item in validations"
              :id="item.id"
              :key="item.id"
              class="password-validation-messages"
              :class="{ 'password-validation-messages--error': !item.isValid }"
            >
              <v-icon :id="item.id + '-button'" small class="password-validation-icons">
                {{ !item.isValid ? 'mdi-close-circle-outline' : 'mdi-check-circle-outline' }}
              </v-icon>
              <span>{{ item.text }}</span>
            </div>
          </v-col>
          <v-col cols="6" class="my-0 py-0">
            <div
              v-show="!isPasswordConfirmationValid"
              class="password-validation-messages password-validation-messages--error"
            >
              <v-icon small class="password-validation-icons">mdi-close-circle-outline</v-icon>
              Senhas não coincidem
            </div>
          </v-col>
        </v-row>
        <v-row class="mx-0">
          <v-col class="px-0">
            <div class="message-obs pt-1">
              <p>* Campos de preenchimento obrigatório</p>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn class="btn-ag-primary" :loading="loadingSave" :disabled="loadingSave" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserService from '@/services/userService.js';
import { mapState, mapActions, mapGetters } from 'vuex';

import { eventBus } from '../../../../main.js';

export default {
  props: ['id'],
  data: () => ({
    valid: false,
    showErrorPassword: false,

    showConfirmPassword: false,
    showConfirmNewPassword: false,
    isPasswordConfirmationValid: true,
    loadingSave: false,
    passwordConfirmMessage: '',
    form: {
      newPassword: null,
      newPasswordConfirmation: null,
    },

    rules: {
      passwordRules: (v) => !!v || 'Preencha uma senha válida',
      password8Rules: (v) => /^(?=.*[a-zA-Z\d]).{8,20}$/.test(v) || 'De 8 a 20 caracteres',
      passwordNumberRules: (v) => /^(?=.*[0-9])/.test(v) || 'Um número de 0 a 9',
      passwordUpperCaseRules: (v) => /^(?=.*[A-Z])/.test(v) || 'Letra maiúscula',
      passwordLowercaseCaseRules: (v) => /^(?=.*[a-z])/.test(v) || 'Letra minúscula',
      passwordSpecialCharacterRules: (v) => /^(?=.*[@!#$%^&+=*()^{}~])/.test(v) || 'Um caracter especial "!@#$%"',
      passwordAlreadyUsed: () => true || this.passwordAlreadyUsedMessage,
    },
  }),

  computed: {
    ...mapGetters({
      validPassword: 'notifications/getValidPassword',
    }),

    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
      loading: (state) => state.loading,
    }),

    ruleNewPasswordConfirmation() {
      return [
        (v) => !!v || 'Nova senha de confirmação é obrigatória',
        () => this.form.newPassword === this.form.newPasswordConfirmation || 'As senhas devem ser iguais',
      ];
    },

    validations() {
      return [
        {
          id: 'sizeChar',
          text: 'De 8 a 20 caracteres',
          isValid: /^(?=.*[a-zA-Z\d]).{8,20}$/.test(this.form.newPassword) === true,
        },
        {
          id: 'sizeNumber',
          text: 'Um número de 0 a 9',
          isValid: /^(?=.*[0-9])/.test(this.form.newPassword) === true,
        },
        {
          id: 'upper',
          text: 'Letra maiúscula',
          isValid: /^(?=.*[A-Z])/.test(this.form.newPassword) === true,
        },
        {
          id: 'lower',
          text: 'Letra minúscula',
          isValid: /^(?=.*[a-z])/.test(this.form.newPassword) === true,
        },
        {
          id: 'specialChar',
          text: 'Um caracter especial "!@#$%"',
          isValid: /^(?=.*[@!#$%^&+=*()^{}~])/.test(this.form.newPassword) === true,
        },
        {
          id: 'passwordAlready',
          text: 'Senha diferente das três últimas',
          isValid: !this.validPassword,
        },
      ];
    },
  },

  async mounted() {
    this.userForm = this.user;
  },

  methods: {
    ...mapActions('notifications', ['wasLast3Passwords1']),
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    close() {
      this.isEditMode = false;
      this.form = this.formEdit;
    },

    matchPassword(isNewPass) {
      if (isNewPass && !this.form.newPasswordConfirmation) {
        this.isPasswordConfirmationValid = true;
      } else if (this.form.newPassword === this.form.newPasswordConfirmation) {
        this.isPasswordConfirmationValid = true;
      } else {
        this.isPasswordConfirmationValid = false;
      }
    },

    async wasLast3Passwords(value) {
      if (value) {
        let data = {
          identificator: this.userForm.emailAddress,
          password: value,
        };

        await this.wasLast3Passwords1(data).then(() => {
          if (this.validPassword == true) {
            this.passwordAlreadyUsedMessage = '';
            this.rules.passwordAlreadyUsed = false;
          } else {
            this.passwordAlreadyUsedMessage = 'Senha diferente das três últimas';
            this.rules.passwordAlreadyUsed = true;
          }
        });
      }
    },

    async updatePassword(value) {
      await this.wasLast3Passwords(value);

      const isValid = this.$refs.passwordFieldOne.validate();

      this.showErrorPassword = !isValid;
    },

    async save() {
      await this.$refs.form.validate();
      if (!this.valid) return;
      if (this.form.newPassword === this.form.newPasswordConfirmation) {
        const user = { ...this.form };
        user.newPassword = btoa(user.newPassword);
        user.newPasswordConfirmation = btoa(user.newPasswordConfirmation);
        user.email = { ...this.user }.emailAddress;

        await UserService.updatePassword(user)
          .then(() => {
            this.showToast('Alterações realizadas com sucesso!', 'success', 78);
          })
          .catch((error) => {
            this.showToast(error.response.data.message, 'error', 78);
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/design/variables.scss';

.password-validation-messages {
  display: flex;
  gap: 8px;
  align-items: center;
  color: $feedback-color-success-medium;
  font-size: 12px;
  &--error {
    color: $feedback-color-error-medium;
  }
  .password-validation-icons {
    font-size: 14px;
    color: inherit;
  }
}

.message-obs {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $neutral-color-high-medium;
}

.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: $neutral-color-low-light !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}
</style>
